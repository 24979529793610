import React, { useState } from "react";
import { Form } from "@formio/react";
import avmForm from "../../assets/avm-reservation-no-api.json";
import "../../styles/form-renderer.css";
import "../../styles/standard.css";
import "../../styles/tablestyle.css";
import "../../styles/loading-dialogue.css";
import {
  reserveAccounts,
  getAccount,
} from "../../services/Avm-Reservation-Service";
import { Container, Col, Row, Button } from "react-bootstrap";
import AvmReservationModel from "../models/Avm-Reservation-Model";
import { isSuccessfulResponse } from "../../utils/isSuccessfulResponse";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import AvmGetResponse from "../../types/AvmGetResponse";
import AvmPostResponse from "../../types/AvmPostResponse";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Parcel from "single-spa-react/parcel";

interface TableData {
  rsrvid: string;
  gspAccount: string;
  accountNumber: string;
  requestStatus: string;
  accountStatus: boolean;
  currencyCode: string;
  usageCode: string;
  timestamp: string;
}

const columnNames = {
  timestamp: "Timestamp",
  rsrvid: "Reservation ID",
  requestStatus: "Request Status",
  accountStatus: "Account Status",
  gspAccount: "Custody Account Number",
  accountNumber: "Cash Account Number",
  currencyCode: "Currency Code",
  usageCode: "Usage Code",
};

function AvmForm() {
  const [openErrorDialog, setErrorDialog] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formDefinition, setFormDefinition] = useState(avmForm);
  const [loading, setLoading] = useState(false);
  const [currentTableData, setCurrentTableData] = useState<TableData[]>([]);
  const [latestTableData, setLatestTableData] = useState<TableData[]>([]);

  const LOADING_CIRCLE_SIZE = 70;

  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);

  const onFormChange = (event) => {
    if (event && event.data && event.data.requestType) {
      const updatedForm = { ...formDefinition };

      const requestTypeComponent = updatedForm.components.find(
        (component) => component.key === "requestType"
      );

      if (event.data.requestType === "GSP") {
        requestTypeComponent.defaultValue = "GSP";
      } else if (event.data.requestType === "IMMS") {
        requestTypeComponent.defaultValue = "IMMS";
      } else if (event.data.requestType === "BOTH") {
        requestTypeComponent.defaultValue = "BOTH";
      }
      setFormDefinition(updatedForm);
    }
  };

  const handleGet = async (rsrvid?: string) => {
    try {
      const response: AvmGetResponse = await getAccount(rsrvid);
      const responseData =
        Array.isArray(response.data.cashAccountsData) &&
        response.data.cashAccountsData.length > 0
          ? response.data.cashAccountsData
          : response.data.gspAccountsData;
      const timestamp = new Date().toLocaleString();
      const transformedData: TableData[] = responseData
        .flat()
        .map((item: any) => ({
          rsrvid: item.reservationKey,
          requestStatus: isSuccessfulResponse(response) ? "Success" : "Failure",
          accountStatus: item.status,
          gspAccount: item.gspAccount,
          accountNumber: item.accountNumber || " ",
          currencyCode: item.currencyCode || " ",
          usageCode: item.usageCode || " ",
          timestamp: timestamp,
        }));
      setCurrentTableData(transformedData);
      setIsRequestDialogOpen(true);
      return response;
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.toString());
      setErrorDialog(true);
    }
  };

  function transformUsages(formData: any) {
    if (formData.multifield && Array.isArray(formData.multifield)) {
      formData.multifield.forEach((field: any) => {
        if (field.usages && Array.isArray(field.usages)) {
          field.usages = field.usages.map((usage: any) => usage.value);
        }
      });
    }
    return formData;
  }

  const submitForm = async (formData) => {
    setLoading(true);
    try {
      transformUsages(formData);
      const response: AvmPostResponse = await reserveAccounts(
        formData.toJson()
      );
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.toString());
      setErrorDialog(true);
    }
  };

  const handleSubmitForm = async (submission) => {
    try {
      const submittedForm = new AvmReservationModel(submission.data);

      if (submittedForm.validate() === null) {
        const submitResponse = await submitForm(submittedForm);
        const submitRsrvid = submitResponse.data.rsrvid;
        await handleGet(submitRsrvid);
      }
    } catch (error) {
      setErrorMessage(error.toString());
      setErrorDialog(true);
    }
  };

  const handleCloseErrorDialog = () => {
    setErrorDialog(false);
  };

  const handleOpenDialog = () => {
    setIsRequestDialogOpen(true);
    setCurrentTableData([]);
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="title-container">
            <span></span>
            <h2 className="page-headers">Account Reservation Form</h2>
            <div className={"button-padding"}>
              <Button
                className="header-view-response-button"
                onClick={handleOpenDialog}
              >
                View Latest Reservations
              </Button>
            </div>
          </div>
          <div className="main">
            <Form
              form={formDefinition}
              onChange={onFormChange}
              onSubmit={handleSubmitForm}
            />
          </div>
        </Col>
      </Row>
      <React.Fragment>
        <Dialog
          open={openErrorDialog}
          keepMounted
          onClose={handleCloseErrorDialog}
        >
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Oops something went wrong! {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErrorDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <React.Fragment>
        <Dialog open={loading} keepMounted maxWidth={"xs"} fullWidth={true}>
          <DialogContent className="dialog-content-centered">
            <CircularProgress size={LOADING_CIRCLE_SIZE} />
            <DialogContentText className="loading-text">
              Loading...
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </React.Fragment>
      <React.Fragment>
        <Parcel
          config={() => import("@goo/dialog-parcel")}
          handleError={(err) => console.log(err)}
          parcelDidMount={() => console.log("React parcel mounted")}
          isRequestDialogOpen={isRequestDialogOpen}
          key={isRequestDialogOpen ? "open" : "closed"}
          setIsRequestDialogOpen={setIsRequestDialogOpen}
          currentTableData={currentTableData}
          latestTableData={latestTableData}
          setLatestTableData={setLatestTableData}
          columnNames={columnNames}
        />
      </React.Fragment>
    </Container>
  );
}

export default AvmForm;
