import { RegisterApplicationConfig } from "single-spa";

function bootstrap() {
    return Promise.resolve();
}

function mount() {

    return new Promise<void>((resolve) => {

        const iframe = document.createElement('iframe');
        iframe.id = 'modular-iframe';
        iframe.src = 'https://cdn.qa.bnymellon.com/ww/goo/modular/build/index.html';
        iframe.style.cssText = `width: 100%; height: 100%; border: none;`;
        iframe.onload = () => {
          resolve();
        };

        document.body.appendChild(iframe);

    });
}

function unmount() {
    return new Promise<void>((resolve) => {
        removeElement(document.getElementById('modular-iframe'));
        resolve();
    });
}

function removeElement(element: HTMLElement) {
    if (element) {
        element.remove();
    }
}

export default function modularAppConfig(): RegisterApplicationConfig {

    const app: RegisterApplicationConfig = {
        name: 'modular',
        app: {
            bootstrap: bootstrap,
            mount: mount,
            unmount: unmount,
        },
        activeWhen: (location) => location.pathname.startsWith('/modular'),

    }
    return app;
}
