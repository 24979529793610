// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-head {
    background-color: rgb(227, 235, 237);
}

.MuiTableCell-head.table-bold-text {    font-weight: bold;}
`, "",{"version":3,"sources":["webpack://./src/styles/tablestyle.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA,wCAAwC,iBAAiB,CAAC","sourcesContent":[".table-head {\n    background-color: rgb(227, 235, 237);\n}\n\n.MuiTableCell-head.table-bold-text {    font-weight: bold;}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
