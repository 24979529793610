// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-view-response-button {
    display: flex;
    background-color: #00243D;
}

.title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.page-headers {
    display: flex;
    justify-content: center;
    padding-top: 1%;
    padding-left: 160px;
}

.main {
    padding-top: 1%;
    padding-bottom: 10%;
}

.button-padding {
    padding-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/form-renderer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".header-view-response-button {\n    display: flex;\n    background-color: #00243D;\n}\n\n.title-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.page-headers {\n    display: flex;\n    justify-content: center;\n    padding-top: 1%;\n    padding-left: 160px;\n}\n\n.main {\n    padding-top: 1%;\n    padding-bottom: 10%;\n}\n\n.button-padding {\n    padding-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
