import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Collapse,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "react-bootstrap";
import ResultsTable from "./ResultsTable";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";

const CreatedAccountsDialog = ({
  isRequestDialogOpen,
  setIsRequestDialogOpen,
  currentTableData,
  latestTableData,
  setLatestTableData,
  columnNames,
}) => {
  const [isExportEnabled, setIsExportEnabled] = useState(
    currentTableData.length > 0
  );
  const [openPreviousResponses, setOpenPreviousResponses] = useState(
    currentTableData.length == 0
  );

  useEffect(() => {
    if (isRequestDialogOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  });

  const handleExportToExcel = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const dataWithHeaders = currentTableData.map((row) => {
      const rowData = {};
      Object.keys(columnNames).forEach((key) => {
        if (key !== "requestStatus") {
          rowData[columnNames[key]] = row[key] || "";
        }
      });
      return rowData;
    });

    const worksheet = XLSX.utils.json_to_sheet(dataWithHeaders);
    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    XLSX.writeFile(workbook, `Results_Details_${currentDate}.xlsx`);
  };

  const handleCloseRequestDialog = () => {
    setIsRequestDialogOpen(false);
    setLatestTableData((prevData: any) => [...currentTableData, ...prevData]);
    setIsExportEnabled(false);
  };

  return (
    <>
      <Dialog
        open={isRequestDialogOpen}
        keepMounted
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleCloseRequestDialog}
      >
        <DialogTitle>Entry Details</DialogTitle>
        <DialogContent>
          <ResultsTable
            data={currentTableData}
            showRequestStatus={true}
            columnNames={columnNames}
          />
          <div style={{ marginTop: "20px" }}>
            <IconButton
              onClick={() => {
                setOpenPreviousResponses(!openPreviousResponses);
              }}
              aria-expanded={openPreviousResponses}
              aria-label="show previous responses"
            >
              <ExpandMoreIcon />
            </IconButton>
            <span>Latest Entries</span>
            <Collapse in={openPreviousResponses} timeout="auto" unmountOnExit>
              <div style={{ height: 400, width: "100%" }}>
                <ResultsTable
                  data={latestTableData}
                  showRequestStatus={false}
                  columnNames={columnNames}
                />
              </div>
            </Collapse>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRequestDialog}>Close</Button>
          {isExportEnabled && (
            <Button onClick={handleExportToExcel}>Export to Excel</Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreatedAccountsDialog;
