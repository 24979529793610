import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";

interface TableData {
  [key: string]: any;
}

interface ReservationTableProps {
  data: TableData[];
  showRequestStatus: boolean;
  columnNames: { [key: string]: string };
}

function ResultsTable({
  data,
  showRequestStatus,
  columnNames,
}: ReservationTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        {Object.keys(columnNames).some((key) =>
          data.some((row) => row[key])
        ) && (
          <TableHead className="table-head">
            <TableRow>
              {Object.keys(columnNames).map(
                (key) =>
                  (columnNames[key] !== "Request Status" ||
                    showRequestStatus) && (
                    <TableCell key={key} className="table-bold-text">
                      {columnNames[key]}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {Object.keys(columnNames).map(
                (key) =>
                  (columnNames[key] !== "Request Status" ||
                    showRequestStatus) && (
                    <TableCell key={key}>
                      {key === "requestStatus" && showRequestStatus ? (
                        <Chip
                          label={row[key]}
                          variant="outlined"
                          color={row[key] === "Success" ? "success" : "error"}
                          sx={{ width: "85px" }}
                        />
                      ) : (
                        row[key]
                      )}
                    </TableCell>
                  )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ResultsTable;
