import AvmForm from "../components/AvmForm/AvmForm";

function Home() {
  return (
    <div>
      <AvmForm></AvmForm>
    </div>
  );
}

export default Home;
