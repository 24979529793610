import AvmPostResponse from "../types/AvmPostResponse";
import AvmGetResponse from "../types/AvmGetResponse";
import apiClient from "../types/ApiClient";

export const reserveAccounts = async (
  accountsInfo
): Promise<AvmPostResponse> => {
  try {
    const response = await apiClient.post(`/`, accountsInfo, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAccount = async (rsrvid: string): Promise<AvmGetResponse> => {
  try {
    const response = await apiClient.get(`/${rsrvid}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
