import cashAccountsDataModel from "./cashAccountsDataModel";
import { v4 as uuidv4 } from "uuid";

export default class AvmReservationModel {
  private uid: string;
  private rsrvid: string;
  private requestType: string;
  private gspAccountNumber?: string[];
  private noOfGspAccounts?: string;
  private multifield: cashAccountsDataModel[];
  constructor(formData) {
    this.uid = "" + uuidv4();
    this.rsrvid = this.uid;
    this.requestType = formData.requestType;
    this.gspAccountNumber = formData.gspAccountNumber;
    this.noOfGspAccounts = formData.noOfGspAccounts;
    this.multifield = formData.cashAccountsData;
  }

  validate() {
    if (this.requestType === null) {
      return "Request Type is required";
    }
    if (this.requestType === "IMMS" && this.gspAccountNumber === null) {
      return "Custody Account Number(s) is required";
    }
    if (
      (this.requestType === "GSP" || this.requestType === "BOTH") &&
      this.noOfGspAccounts === null
    ) {
      return "Number of Custody Accounts is required";
    }
    if (this.multifield === null) {
      return "Cash Accounts Data is required";
    }
    return null;
  }

  toJson() {
    return {
      uid: this.uid,
      rsrvid: this.rsrvid,
      requestType: this.requestType,
      gspAccountNumber: this.gspAccountNumber,
      noOfGspAccounts: this.noOfGspAccounts,
      multifield: this.multifield,
    };
  }
}
